<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <el-card>
            <search-filter
              ref="searchFilter"
              :searchName="'搜索团队'"
              :keywordOptions="{
                placeholder: '请输入团队名称或创建人',
                prop: 'keyword',
                autoName: 'team',
                autocomplete: true,
              }"
              :searchOptions="[
                {
                  name: 'el-form-item',
                  options: {
                    label: '我的',
                    prop: 'my',
                    type: 'radio',
                    data: { name: 'user_group', keys: 'my' },
                    defaultValue: '全部',
                  },
                },
                {
                  name: 'el-form-item',
                  options: {
                    label: '类别',
                    prop: 'type',
                    type: 'radio',
                    data: { name: 'user_group', keys: 'type' },
                    defaultValue: '全部',
                  },
                },
              ]"
              :otherOptions="[
                {
                  name: 'el-form-item',
                  options: {
                    label: '地区',
                    prop: 'province_id',
                    type: 'select',
                    placeholder: '请选择地区',
                    data: {
                      name: 'user_group',
                      keys: 'province_id',
                      isAll: false,
                    },
                  },
                },
              ]"
              v-model="where"
              :total="total"
              @search="currentChange(1)"
            ></search-filter>
            <div class="operate">
              <el-button
                type="primary"
                icon="el-icon-edit-outline"
                size="small"
                style="height: 30px"
                v-if="userInfo.type == 2"
                :disabled="!$isPowers('teamCreate')"
                @click="myManageList(((where.my = 2), 1))"
              >
                我管理的
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-document-checked"
                size="small"
                style="height: 30px"
                :disabled="!$isPowers('teamCreate')"
                @click="myJoinList(((where.my = 1), 1))"
              >
                我加入的
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-circle-plus-outline"
                size="small"
                style="height: 30px"
                :disabled="!$isPowers('teamCreate')"
                @click="goNewProblemList"
              >
                新建团队
              </el-button>
            </div>
          </el-card>
          <div class="table-content">
            <TeamList
              :operationType="'copyAndDownload'"
              :isSelection="false"
              :tableData="tableData"
              @getList="getList"
              :height="700"
              v-loading="loading"
            ></TeamList>
            <div class="pagination">
              <el-pagination
                background
                layout="total,sizes,prev, pager, next"
                :total="total"
                @current-change="currentChange"
                :page-size="pageSize"
                :current-page="page"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- <div class="content-right">
          <Advertising></Advertising>
          <HotRecommend class="margin"></HotRecommend>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { teamList } from "@/api/team.js";
import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
import TeamList from "./components/TeamList.vue";
import SearchFilter from "@/components/com/SearchFilter";
export default {
  components: {
    SearchFilter,
    Advertising,
    HotRecommend,
    TeamList,
  },
  data() {
    return {
      where: {},
      info: {},
      page: 1,
      pageSize: 50,
      total: 0,
      loading: false,
      tableData: [],
      sort:""
    };
  },
  methods: {
    routeUpdate() {
      this.where = { ...this.$route.query, ...this.$route.query.params };
    },
    // 团队列表
    getTeamList(params) {
      this.loading = true;
      teamList({
        ...this.where,
        ...(params || {}),
        page: this.page,
        page_size: this.pageSize,
        sort:this.sort
      })
        .then(({ data }) => {
          this.loading = false;
          this.tableData = data.data;
          this.total = data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getList(sort){
      console.log(sort,"sort")
      this.sort=sort
      this.getTeamList();
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getTeamList();
    },
    // 点击新建团队
    goNewProblemList() {
      if (!localStorage.getItem("user_token")) {
        return (this.$store.state.lodinShow = true);
      }
      this.$router.push({ path: "/establishTeamInfo" });
    },
    // 我管理的
    myManageList(){
      this.getTeamList( this.where);
     
    },
    // 我加入的
    myJoinList(){
      this.getTeamList( this.where);
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-card__body {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .classify {
      flex: 1;
    }
  }
}
.myTeam {
  background-color: #fff;
  border: 1px solid #00957e;
  color: #00957e;
  height: 24px;
  // box-sizing: border-box;
}
.pagination {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    // width: 70%;
    width: 100%;
    .operate {
      display: flex;
      justify-content: flex-end;

      .new_problemList {
        margin-left: 1rem;
      }
    }
    .table-content {
      margin: 20px 0 0;
      padding: 0 1rem 1rem;
      background-color: #fff;
    }
  }
  .content-right {
    width: 27%;
    // .margin {
    //   margin-top: 40px;
    // }
  }
}
</style>