var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problemList"},[_c('div',{staticClass:"content"},[_c('Breadcrumb'),_c('div',{staticClass:"box"},[_c('div',{staticClass:"content-left"},[_c('el-card',[_c('search-filter',{ref:"searchFilter",attrs:{"searchName":'搜索团队',"keywordOptions":{
              placeholder: '请输入团队名称或创建人',
              prop: 'keyword',
              autoName: 'team',
              autocomplete: true,
            },"searchOptions":[
              {
                name: 'el-form-item',
                options: {
                  label: '我的',
                  prop: 'my',
                  type: 'radio',
                  data: { name: 'user_group', keys: 'my' },
                  defaultValue: '全部',
                },
              },
              {
                name: 'el-form-item',
                options: {
                  label: '类别',
                  prop: 'type',
                  type: 'radio',
                  data: { name: 'user_group', keys: 'type' },
                  defaultValue: '全部',
                },
              },
            ],"otherOptions":[
              {
                name: 'el-form-item',
                options: {
                  label: '地区',
                  prop: 'province_id',
                  type: 'select',
                  placeholder: '请选择地区',
                  data: {
                    name: 'user_group',
                    keys: 'province_id',
                    isAll: false,
                  },
                },
              },
            ],"total":_vm.total},on:{"search":function($event){return _vm.currentChange(1)}},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}}),_c('div',{staticClass:"operate"},[(_vm.userInfo.type == 2)?_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","icon":"el-icon-edit-outline","size":"small","disabled":!_vm.$isPowers('teamCreate')},on:{"click":function($event){_vm.myManageList(((_vm.where.my = 2), 1))}}},[_vm._v(" 我管理的 ")]):_vm._e(),_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","icon":"el-icon-document-checked","size":"small","disabled":!_vm.$isPowers('teamCreate')},on:{"click":function($event){_vm.myJoinList(((_vm.where.my = 1), 1))}}},[_vm._v(" 我加入的 ")]),_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","icon":"el-icon-circle-plus-outline","size":"small","disabled":!_vm.$isPowers('teamCreate')},on:{"click":_vm.goNewProblemList}},[_vm._v(" 新建团队 ")])],1)],1),_c('div',{staticClass:"table-content"},[_c('TeamList',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"operationType":'copyAndDownload',"isSelection":false,"tableData":_vm.tableData,"height":700},on:{"getList":_vm.getList}}),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"total,sizes,prev, pager, next","total":_vm.total,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"current-change":_vm.currentChange}})],1)],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }